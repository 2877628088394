import React, { useState, useRef } from "react"
import { Link } from "gatsby"
import PriceOption from "./price-option"
import PriceOptionText from "./price-option-text"
const Join = ({
  handleClick,
  slug,
  modelName,
  def_nats,
  siteid,
  options,
  theSlideInfo,
}) => {
  const [ageCheck, setAgeCheck] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [password, setPassword] = useState("")
  const [payment, setPayment] = useState("14")
  const [email, setEmail] = useState("")
  const [hasError, setHasError] = useState({ type: "", error: "" })
  const [selectedOption, setSelectedOption] = useState(options[0].optionid)
  const theForm = useRef(null)
  const handleFormSubmit = e => {
    if (email.indexOf(".") === -1 || email.indexOf("@") === -1) {
      setHasError({
        type: "email",
        error: "Not a valid email!",
      })
      return
    }

    let regex = /[!#$%^&+*(),=?"':\/\\{}|<>]/.test(email)

    if (regex) {
      setHasError({
        type: "email",
        error: "Only special characters allowed are @ and .",
      })
      return
    }

    if (password.length < 6) {
      setHasError({
        type: "password",
        error: "Password must be at least 6 characters",
      })
      return
    }

    if (password.includes("&")) {
      setHasError({
        type: "password",
        error: "Please do not use the & character in your password.",
      })
      return
    }

    if (password.length > 16) {
      setHasError({
        type: "password",
        error: "Password can at most be 16 characters!",
      })
      return
    }
    setSubmitting(true)
    setHasError({ type: "", error: "" })
    if (payment === "99") {
      //redirect to bitcoin here........
      let url = `https://join.addfriends.com/strack/${def_nats}/${siteid}:river/0/1/join?step=special&nats=${def_nats}&special[${selectedOption}]=1&signup[username]=${encodeURIComponent(
        email
      )}&signup[password]=${encodeURIComponent(password)}`
      window.location.href = url
    } else {
      theForm.current.submit()
    }

    return true
  }
  return (
    <div className="modal_wrapper">
      <div className="modal_form">
        <div className="wrapper_form">
          <div className="modal_headline">
            <div className="avatar">
              <img
                src={`https://static.addfriends.com/images/${slug}.jpg`}
                alt=""
              />
              <div className="network"></div>
            </div>
            <div className="title">
              Sign Up
              <sub>
                for <strong>{modelName}</strong>
              </sub>
            </div>
            <div className="second">
              <button
                type="button"
                className="modal_close"
                onClick={handleClick}
              >
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 1024 1024"
                >
                  <path d="M624.779 512.001l382.86-382.349c10.106-10.055 16.36-23.973 16.36-39.353 0-0.057 0-0.113 0-0.17v0.009c0-0.078 0.001-0.171 0.001-0.264 0-15.37-6.255-29.279-16.358-39.32l-33.527-33.527c-10.070-10.063-23.978-16.287-39.341-16.287-0.086 0-0.173 0-0.259 0.001h0.013c-0.091-0.001-0.199-0.001-0.306-0.001-15.343 0-29.233 6.225-39.28 16.287l-0.001 0.001-382.933 382.349-382.86-382.349c-10.070-10.063-23.978-16.287-39.341-16.287-0.086 0-0.173 0-0.259 0.001h0.013c-0.082 0-0.179-0.001-0.276-0.001-15.353 0-29.252 6.225-39.31 16.287l-33.597 33.597c-21.838 21.765-21.838 57.261 0 79.026l382.86 382.349-382.86 382.276c-10.063 10.059-16.288 23.958-16.288 39.31 0 0.097 0 0.194 0.001 0.291v-0.015c0 14.972 5.77 28.996 16.287 39.586l33.597 33.451c10.032 10.105 23.932 16.361 39.292 16.361 0.103 0 0.207 0 0.31-0.001h-0.016c14.972 0 29.069-5.843 39.586-16.36l382.86-382.422 382.933 382.349c10.032 10.105 23.932 16.36 39.292 16.36 0.078 0 0.156 0 0.233 0h-0.012c0.091 0.001 0.199 0.001 0.306 0.001 15.38 0 29.298-6.255 39.351-16.359l0.002-0.002 33.524-33.451c10.106-10.044 16.361-23.952 16.361-39.322 0-0.093 0-0.186-0.001-0.278v0.014c0-0.075 0.001-0.163 0.001-0.252 0-15.351-6.256-29.24-16.357-39.258l-0.004-0.004z"></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="modal_content">
            <h1 className="signup">Subscription Benefits</h1>
            <div className="benefits">
              <div className="benefitItem">
                <span>+</span>Full access to my content
              </div>
              <div className="benefitItem">
                <span>+</span>1 on 1 direct messaging
              </div>
              {/* <div className="benefitItem">
                <span>+</span>1 on 1 video call requests
              </div> */}
              <div className="benefitItem">
                <span>+</span>request custom videos
              </div>
              <div className="benefitItem">
                <span>+</span>easy cancel anytime
              </div>
            </div>

            <form
              action={`https://join.addfriends.com/strack/${def_nats}/${siteid}:river/0/1/join`}
              id="form_signup"
              // onSubmit={handleFormSubmit}
              ref={theForm}
            >
              {theSlideInfo !== "0.0" ? (
                <input
                  type="hidden"
                  name="signup[custom4]"
                  value={theSlideInfo}
                />
              ) : null}
              <input type="hidden" name="step" value="signup" />
              <input
                type="hidden"
                name="cascade"
                id="cascade"
                value={payment}
              />
              <input
                type="hidden"
                name="signup[optionid]"
                id="optionid"
                value={selectedOption}
              />
              <input type="hidden" name="nats" id="nats" value={def_nats} />
              <input
                type="hidden"
                name="nextra[EPOCH][site_subcat]"
                value={slug}
              />
              <input
                type="hidden"
                name="nextra[EPOCH][topimage]"
                value="604239_addfriends2.png"
              />
              <div className="wrap">
                <div className="row">
                  <input
                    type="email"
                    name="signup[username,email:1:1:128:::email_check]"
                    className="input"
                    placeholder="Your Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                  />
                  {hasError.type === "email" ? (
                    <div className="formError">{hasError.error}</div>
                  ) : null}
                </div>
                <div className="row">
                  <input
                    type="text"
                    name="signup[password:1:6:16:::password_check_detailed;0|0|0|0|0|0|0]"
                    className="input"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    autoCapitalize="off"
                    autoCorrect="off"
                    spellCheck="false"
                    autoComplete="off"
                    required
                  />
                  {hasError.type === "password" ? (
                    <div className="formError">{hasError.error}</div>
                  ) : null}
                </div>
              </div>
              <div className="checking_wrap">
                <input
                  type="checkbox"
                  className="checkbox"
                  id="acceptChecking"
                  checked={ageCheck}
                  onChange={() => setAgeCheck(a => !a)}
                />
                <label htmlFor="acceptChecking" className="label">
                  By checking this box, I certify that I am at least 18 years
                  old & agree to the <Link to="/terms">Terms of Service</Link>{" "}
                  and <Link to="/privacy">Privacy Policy</Link>
                </label>
              </div>
              <div className="paymentMethods">
                <div
                  className="paymentItem"
                  onClick={() => {
                    if (payment === "99") {
                      setSelectedOption(options[0].optionid)
                    }
                    setPayment("14")
                  }}
                >
                  <img
                    src="http://static.addfriends.com/images/creditcard.png"
                    alt=""
                    className={payment === "14" ? "active" : ""}
                  />
                </div>
                <div
                  className="paymentItem"
                  onClick={() => {
                    if (payment === "99") {
                      setSelectedOption(options[0].optionid)
                    }
                    setPayment("4")
                  }}
                >
                  <img
                    src="http://static.addfriends.com/images/xpaypal.png"
                    alt=""
                    className={payment === "4" ? "active" : ""}
                  />
                </div>
                <div
                  className="paymentItem"
                  onClick={() => {
                    if (payment !== "99") {
                      let newDef = options.find(item => item.coin === "y")
                      if ("optionid" in newDef) {
                        setSelectedOption(newDef.optionid)
                      }
                    }
                    setPayment("99")
                  }}
                >
                  <img
                    src="http://static.addfriends.com/images/bitcoin.png"
                    alt=""
                    className={payment === "99" ? "active" : ""}
                  />
                </div>
              </div>
              <div className="wrap">
                {options
                  ? options
                      .filter(option => {
                        if (payment === "99") {
                          return option.coin === "y"
                        }
                        return option.coin === "n"
                      })
                      .map((option, index) => {
                        if (index === 0) {
                          return (
                            <PriceOption
                              option={option}
                              setSelectedOption={setSelectedOption}
                              key={`po${option.optionid}`}
                              ageCheck={ageCheck}
                              selectedOption={selectedOption}
                              handleFormSubmit={handleFormSubmit}
                            />
                          )
                        }
                        return (
                          <PriceOptionText
                            key={`po${option.optionid}`}
                            option={option}
                            ageCheck={ageCheck}
                            handleFormSubmit={handleFormSubmit}
                            setSelectedOption={setSelectedOption}
                          />
                        )
                      })
                  : null}
              </div>
            </form>
            {submitting ? (
              <div className="processing">
                <div className="nk-spinner big"></div>
              </div>
            ) : null}
          </div>
          <div className="modal_footer">
            Have an account?{" "}
            <a href={`/vip?cb=${new Date().getTime()}`}>Sign In</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Join
