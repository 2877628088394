import React from "react"
const PriceOptionText = ({
  option,
  setSelectedOption,
  ageCheck,
  handleFormSubmit,
}) => {
  return (
    <button
      className="priceOptionAlt"
      onClick={() => {
        setSelectedOption(option.optionid)
        if (ageCheck) {
          handleFormSubmit()
        }
      }}
    >
      OR SUBSCRIBE FOR A YEAR AND GET 2 MONTHS FREE
    </button>
  )
}

export default PriceOptionText
