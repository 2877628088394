import React from "react"
const PriceOption = ({
  option,
  selectedOption,
  setSelectedOption,
  handleFormSubmit,
  ageCheck,
}) => {
  const cost = option.cost.split(" / ")
  return (
    <div className="box">
      <input
        type="radio"
        name="chek_radio"
        className="choice_flag"
        id={`radio${option.optionid}`}
        defaultChecked={selectedOption === option.optionid}
        onClick={() => {
          setSelectedOption(option.optionid)
          if (ageCheck) {
            handleFormSubmit()
          }
        }}
        disabled={!ageCheck}
      />
      <label htmlFor={`radio${option.optionid}`} className="radio_label">
        <div className="price">
          SUBSCRIBE FOR {cost[0]}/{cost[1]}
        </div>
      </label>
    </div>
  )
}

export default PriceOption
